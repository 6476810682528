.main_img-wrap {
    background-color: $color-white;
    width: 100%;
    margin-bottom: 1em;
    padding: 0;
}

.main_img-info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    padding: 2em 0;
}

.main_img-info-404 {
    background: $color-black url(/images/404-bkg.jpg) no-repeat;
}

.main_img-info-about {
    background: $color-black url(/images/about-bkg.jpg) no-repeat;
}

.main_img-info-contact {
    background: $color-black url(/images/contact-bkg.jpg) no-repeat;
}

.main_img-info-faq {
    background: $color-black url(/images/faq-bkg.jpg) no-repeat;
}

.main_img-info {
    background-size: cover;
    background-position: center;
}

.main_img-info-massage {
    background: $color-black url(/images/massage-bkg.jpg) no-repeat;
}

.main_img-heading {
    font-size: 2.45em;
    margin: 1em auto;
    color: $color-white;
    font-weight: 100;

    @include mq(tablets) {
        color: darken($color-green, 10%);
    }
}

.main_img-content-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: $max-width;
    height: 100%;

    @include mq(tablets) {
        padding: 0 1em;
    }

    @include mq($max-width) {
        padding: 0;
    }
}

.main_img-content {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 2em;
    background-color: rgba($color-black, 0.6);
    color: $color-white;

    //This fixes a safari bug where the text doesn't wrap.
    > div {
        flex: 0 1 auto;
    }

    @include mq(tablets) {
        flex-basis: 50%;
        width: 50%;
        max-width: 50%;
        height: 90%;
        background-color: rgba($color-white, 0.9);
        color: darken($color-green, 10%);
    }

    @include mq(wide_tablets) {
        flex-basis: 40%;
        width: 40%;
        max-width: 40%;
    }
}