.footer-wrap {
    border-top: 1px solid rgba($color-green, 0.2);
}

.footer {
    max-width: 100%;
    margin: 0 auto;
    padding: 1em;
    text-align: center;
    @include mq(wide_handhelds) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: stretch;
        text-align: left;
    }
    @include mq($max-width) {
        padding: 1em 0 1.5em;
        max-width: $max-width;
    }
}

.footer-right {
    @include mq(wide_handhelds) {
        text-align: right;
    }
}

.footer-left {
    margin-bottom: 1em;
    h4 {
        margin-top: 0.5em;
    }

    a:hover {
        border: none;
    }
    svg {
        width: 2em;
        height: 2em;
        fill: $color-green;
    }
    @include mq(tablets) {
        margin-bottom: 0;
    }
}

.footer-copyright {
    flex-basis: 100%;
    width: 100%;
    font-size: 0.9em;
    text-align: center;

    @include mq(wide_tablets) {
         margin-top: 0;
         text-align: right;
    }
}

.footer-hours {
    margin-bottom: 1em;
    h5 {
        margin-top: 0.25em;
        margin-bottom: 0.25em;
    }
}