.content-box {
    margin-bottom: 2em;

    @include mq(wide_tablets) {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        min-height: 23em;
    }
}

.content_box-image-wrap {
    text-align: center;

    img {
        border: 1px solid $color-green;
        @include mq(tablets) {
            width: 23em;
            height: 23em;
            border: none;
        }
    }

    @include mq(wide_tablets) {
        width: 23em;
        max-width: 100%;
        height: 23em;
        margin-right: 1em;
        padding-top: 0;

    }
}

.content_box-copy {
    font-size: 0.9em;
    padding: 1em;

    @include mq(wide_tablets) {
        width: 63%;
        overflow: hidden;
    }
}