.hp-intro_wrap {
    max-width: $max-width-narrow;
    margin: 2em auto;
    h2 {
        font-size: 2em;
        margin-bottom: 0;
    }
}

.hp-testimonials_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    min-height: 28em;
    max-height: 35em;
    max-width: 100%;
    padding: 2em 0;
    text-align: center;
    background: #fff url(/images/hp-testimonials.jpg) no-repeat;
    background-size: cover;
    background-position: center;
}

.testimonial-content {
    font-size: 0.9em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55em;
    max-width: 100%;
    height: 25em;
    padding: 1em 3em;
    background-color: rgba($color-white, 0.65);
    color: darken($color-green, 20%);
    > div {
        flex: 0 1 auto;
    }
}

.testimonial-inner {
    position: relative;
    padding-bottom: 4em;
}

.testimonial-name {
    margin-top: 2em;
    text-align: center;
    text-transform: uppercase;
}

.testimonial-nav {
    position: absolute;
    bottom: 2em;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    a {
        display: inline-block;
        width: 0.75em;
        height: 0.75em;
        margin-right: 0.65em;
        box-shadow: 0px 1px 1px 2px lighten($color-grey-dark,10%);
        border-radius: 50%;

        cursor: pointer;
        &:last-of-type {
            margin-right: 0;
        }

        &:hover {
            text-decoration: none;
        }
    }
}

.hp-map_wrap {
    position: relative;
    z-index: 1;
    a:hover {
        border: none;
    }
}

.hp-map-overlay {
    position: absolute;
    width: 100%;
    height: 31.5em;
    background-color: rgba($color-white, 0.1);
    z-index: 100;
}

.hp-icons-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
    border-bottom: 1px solid $color-green;
}

.hp-icon-wrap {
    flex-basis: 30%;
    padding: 2em;
    text-align: center;
    svg {
        width: 8em;
        height: 8em;
        fill: darken($color-green, 10%);
    }

    &:first-of-type {
        padding-left: 0;
    }

    &:last-of-type {
        padding-right: 0;
    }
}

.content-wrap-fee_schedule {
    flex-flow: row wrap;
    padding: 0;

    @include mq(wide_tablets) {
        flex-flow: row nowrap;
    }
}

.hp-fee_schedule {
    font-size: 0.9em;
    width: 80%;
    margin: 0 auto;
    padding: 2em 0;
    @include mq(wide_handhelds) {
        width: 50%;
    }
    @include mq(wide_tablets) {
        flex-basis: 40%;
        width: 40%;
    }
}

.hp-fee_schedule-img {
    width: 100%;
    height: 20em;

    background: #fff url(/images/calm.jpg) no-repeat;
    @include mq(wide_tablets) {
        width: 60%;
        flex-basis: 60%;
        height: auto;
        margin-left: 1em;
    }
}