h1, h2, h3, h4, h5, h6 {
    line-height: 1.2;
}

h1, h2, h3 {
    font-weight: 100;
}

h1 {
    font-size: 3em;
}

h2 {
    font-size: 2em;
    @include mq(tablets) {
        font-size: 2.25em;
    }

}

h3 {
    font-size: 1.85em;
    margin-top: 1.25em;
    margin-bottom: 0.5em;
}


h4 {
    margin-bottom: 0.25em;
}

strong {
    font-weight: 700;
}

.heading-alternative {
    background-color: $color-black;
    color: $color-white;
    width: 8em;
    margin: 2em auto;
    padding: 0.5em 1em;
    text-align: center;
}

h4.heading-alternative {
    font-weight: 100;
}