.hp-services-wrap {
    background-color: $color-black;
    width: 100%;
    height: 54em;
    padding: 1em 0 0;

    @include mq(wide_tablets) {
    	 padding: 3em 0 0;
    	 height: 42em;
    }
}

.hp-service {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: $max-width;
    height: 32em;
    margin: 0 auto 2em;
    background: $color-black url(/images/services.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    @include mq(wide_tablets) {
    	height: 30em;
    }
}

.hp_services-heading {
    margin: 0 auto 0.5em;
    color: $color-white;
    font-weight: 100;
}

.hp_service-content-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 90%;
    height: 100%;
}

.hp-service-content {
    display: flex;
    align-items: center;
	width: 100%;
    height: 90%;
    padding: 2em;
    background-color: rgba($color-black, 0.9);
    color: $color-grey-light;

    @include mq(wide_tablets) {
	    flex-basis: 40%;
	    width: 40%;
	    max-width: 40%;
	     > div {
	        flex: 0 1 auto;
	    }
    }
}

.hp-service-heading {
    margin: 0;
    font-weight: 100;
}

.hp-service_list {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    text-align: center;
    color: $color-white;

	@include mq(wide_tablets) {
		 display: flex;
	    justify-content: space-between;
	    max-width: $max-width;
	}

    li {
		margin-bottom: 0.5em;
		@include mq(wide_tablets) {
			display: inline-block;
	        margin-right: 2em;
	        margin-bottom: 0;
	        &:last-of-type {
	            margin-right: 0;
        	}
		}
    }

    a { color: $color-white; }
}

.hp_service-copy {
	display: none;
	height: 23em;
	overflow: hidden;
	text-overflow: ellipsis;
}

.hp_service-visible { display: block; }

.service-list_item {
	&:hover {
		cursor: pointer;
	}
	svg {
		width: 0;
		height: 0;
	}
}

.active {

    svg {
		display: block;
        width: 1em;
        height: 1em;
        margin: 0 auto;
        fill: $color-green;
    }
}

.service-action_link {
    position: absolute;
    right: 1em;
    bottom: 0;
    svg {
        width: 1em;
        height: 1em;
        margin: 0 auto;
        fill: $color-green;
        margin-left: 0.5em;
        transform: rotate(180deg);
    }
}